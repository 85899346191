<template>
  <div>
    <b-row>
      <b-col class="mb-1" md="6">
        <b-card :title="$t('Order_Show.Order')" class="h-100">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">{{ $t("Order_Show.ID") }}</th>
                <td class="text-right">{{ orderData.id }}</td>
              </tr>
              <tr>
                <th class="text-left">{{ $t("Order_Show.Code") }}</th>
                <td class="text-right">{{ orderData.code }}</td>
              </tr>
              <tr>
                <th class="text-left">{{ $t("Order_Show.Status") }}</th>
                <td class="text-right">
                  <b-badge variant="primary">
                    {{ orderStatus }}
                  </b-badge>
                </td>
              </tr>
              <tr>
                <th class="text-left">{{ $t("Order_Show.Type") }}</th>
                <td class="text-right">{{ orderData.type }}</td>
              </tr>
              <tr>
                <th class="text-left">{{ $t("Order_Show.Note") }}</th>
                <td class="text-right">{{ orderData.note }}</td>
              </tr>
              <tr>
                <th class="text-left">{{ $t("Order_Show.Date") }}</th>
                <td class="text-right">{{ orderData.created_at }}</td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
      <b-col md="6" class="mb-1">
        <b-card :title="$t('Order_Show.Order_Total')" class="h-100">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">{{ $t("Order_Show.Tax") }}</th>
                <td class="text-right">{{ orderData.tax }}</td>
              </tr>
              <tr>
                <th class="text-left">{{ $t("Order_Show.Fees") }}</th>
                <td class="text-right">{{ orderData.fees }}</td>
              </tr>
              <tr>
                <th class="text-left">{{ $t("Order_Show.Shipping_Price") }}</th>
                <td class="text-right">{{ orderData.shipping_price }}</td>
              </tr>
              <tr>
                <th class="text-left">{{ $t("Order_Show.Discount") }}</th>
                <td class="text-right">
                  {{
                    orderData.coupon != null ? orderData.coupon.percentage : "-"
                  }}
                </td>
              </tr>
              <tr>
                <th class="text-left">{{ $t("Order_Show.Total") }}</th>
                <th class="text-right">{{ orderData.total }}</th>
              </tr>
              <tr>
                <th class="text-left">{{ $t("Order_Show.Total_Paid") }}</th>
                <th class="text-right">{{ orderData.total_paid }}</th>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
    </b-row>
    <b-card :title="$t('Product_Index.Update_Order_Status')">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!-- Active -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Order Status"
                rules="required"
              >
                <b-form-group
                  :label="$t('Product_Index.Order_Status')"
                  label-for="Order Status"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="status"
                    v-model="activeSelect"
                    @input="validationForm"
                    :state="activeSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="activeOptions"
                    :clearable="false"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values , index) in errors_back" :key="index">
          <li v-for="(value , valIndex) in values" :key="valIndex">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
<script>

import { heightTransition } from "@core/mixins/ui/transition";
import { required } from "@validations";
export default {
  
  mixins: [heightTransition],
  data() {
    return {
      showDismissibleAlert: false,
      id: 0,
      status: {},
      errors_back: [],
      required,
      per: null,
      activeSelect: {
        text: "",
        value: "",
      },
      activeOptions: [],
      orderData: {},
      itemStatusSelect: [],
      bundleStatusSelect: [],
      itemStatusOptions: [],
      bundleStatusOptions: [],
      restockBundles: [],
      restockVariants: [],
      orderData2: {
        bundles: [],
        variants: [],
      },
      orderStatus: "",
    };
  },
  created() {
    // request status data
    this.id = this.$route.params.id;
    this.fetchData();
    this.getorderStatus();
  },
  methods: {
    fetchData() {
      axios
        .get("orders/" + this.id)
        .then((result) => {
          this.itemStatusSelect.length = 0;
          this.bundleStatusSelect.length = 0;
          this.itemStatusOptions.length = 0;
          this.bundleStatusOptions.length = 0;
          this.orderData2.bundles.length = 0;
          this.orderData2.variants.length = 0;
          this.restockBundles.length = 0;
          this.restockVariants.length = 0;

          this.orderData = result.data.data;
          this.orderStatus = this.orderData.orderstatus.name;

          this.activeSelect.text = this.orderData.orderstatus.name;
          this.activeSelect.value = this.orderData.orderstatus.slug;
          for (let index in this.orderData.bundles) {
            this.bundleStatusOptions.push([]);
            this.restockBundles.push(false);
            this.bundleStatusSelect.push({
              text: this.orderData.bundles[index].item_status_slug,
              value: this.orderData.bundles[index].item_status_slug,
            });
            //   // request bundle status

            this.orderData2.bundles.push({
              bundle_id: this.orderData.bundles[index].id,
              name: this.orderData.bundles[index].translation.name,
              bundPrice: this.orderData.bundles[index].price,
              originalPrice: this.orderData.bundles[index].original_price,
            });
            //   // end request bundle
          }
          for (let index in this.orderData.variants) {
            this.restockVariants.push(false);
            this.itemStatusSelect.push({
              text: this.orderData.variants[index].item_status_slug,
              value: this.orderData.variants[index].item_status_slug,
            });
            this.itemStatusOptions.push([]);

            this.orderData2.variants.push({
              id: this.orderData.variants[index].id,
              name: this.orderData.variants[index].name,
              price: this.orderData.variants[index].price,
            });
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    // update function to status
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.$bvModal
            .msgBoxConfirm("Please confirm that you want to change status.", {
              title: "Please Confirm",
              size: "sm",
              okVariant: "primary",
              okTitle: "Yes",
              cancelTitle: "No",
              cancelVariant: "outline-secondary",
              hideHeaderClose: false,
              centered: true,
            })
            .then((value) => {
              if (value == true) {
                axios
                  .get(
                    "orders/" +
                      this.id +
                      "/change-status/" +
                      this.activeSelect.value
                  )
                  .then((result) => {
                    this.$swal({
                      position: "center",
                      icon: "success",
                      title: "Your work has been saved",
                      showConfirmButton: false,
                      timer: 1500,
                    });
                    this.fetchData();
                    this.getorderStatus();
                    this.$router.push({ path: "/orders/show/" + this.id });
                  })
                  .catch((err) => {
                    this.$swal({
                      position: "center",
                      icon: "error",
                      title: "Error!",
                      showConfirmButton: false,
                      timer: 1500,
                    });
                    this.errors_back.length = 0;
                    if (err.response.data.data != null) {
                      if (this.isString(err.response.data.data)) {
                        this.errors_back.push({
                          error: err.response.data.data,
                        });
                      } else {
                        this.errors_back = err.response.data.data;
                      }
                      this.showDismissibleAlert = true;
                    } else {
                      this.errors_back = [];
                      this.errors_back.push({
                        error: "internal server error",
                      });
                      this.showDismissibleAlert = true;
                    }
                  });
              } else {
                this.activeSelect.text = this.orderData.orderstatus.name;
                this.activeSelect.value = this.orderData.orderstatus.slug;
                this.activeOptions.length = 0;
                this.getorderStatus();
              }
            });
        }
      });
    },
    itemChangeStatus(id, statusvalue, index, stock) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to change status.", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get(
                "orders-items/" +
                  this.id +
                  "/variant/" +
                  id +
                  "/change-item-status/" +
                  statusvalue +
                  "/" +
                  stock
              )
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
                this.getorderStatus();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          } else {
            this.itemStatusSelect[index].text = this.orderData.variants[
              index
            ].item_status_slug;
            this.itemStatusSelect[index].value = this.orderData.variants[
              index
            ].item_status_slug;

            this.itemStatusOptions[index].length = 0;
          }
        });
    },
    bundleChangeStatus(id, statusvalue, index, stock) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete everything.", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get(
                "orders-items/" +
                  this.id +
                  "/bundle/" +
                  id +
                  "/change-item-status/" +
                  statusvalue +
                  "/" +
                  stock
              )
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
                this.getorderStatus();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          } else {
            this.bundleStatusSelect[index].text = this.orderData.bundles[
              index
            ].item_status_slug;
            this.bundleStatusSelect[index].value = this.orderData.bundles[
              index
            ].item_status_slug;

            this.bundleStatusOptions[index].length = 0;
          }
        });
    },
    getorderStatus() {
      axios
        .get("orders/" + this.id + "/validate-status")
        .then((result) => {
          this.activeOptions.length = 0;

          const data = result.data.data;
          for (let ind in data) {
            this.activeOptions.push({
              text: data[ind].name,
              value: data[ind].slug,
            });
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
